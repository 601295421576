$box-border-color: #aaa;
$box-background: #eee;

.area {
  display: flex;
  flex-flow: row nowrap;

  .block {
    display: flex;

    .card {
      border: 1px solid $box-border-color;
      background: $box-background;
      padding: 0.6rem 0.8rem;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;

      .name {
        display: flex;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }

      .params {
        display: flex;

        .param {
          margin-bottom: 0.5rem;

          label,
          input {
            display: block;
          }

          input {
            width: 8rem;
            border: 1px solid $box-border-color;
          }
        }
      }

      .actions {
        display: flex;
        flex-flow: column nowrap;

        button,
        select {
          margin: 0;
          padding: 0.1rem 0.4rem;
          background: #fff;
          border: 1px solid $box-border-color;
          line-height: 1;
          font-size: 1rem;
        }
      }
    }
  }

  .inputs {
    display: flex;
    flex-flow: column nowrap;

    .input {
      display: flex;
      flex-flow: row nowrap;
      padding-bottom: 1rem;

      .inputCard {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        margin: 0 -1px;
        z-index: 2;

        .inputName {
          background: $box-background;
          padding: 0.2rem;
          border-top: 1px solid $box-border-color;
          border-bottom: 1px solid $box-border-color;
        }
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}
